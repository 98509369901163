(function( $ ) {
	
	const $header = $('.js-site-header');
	const $body = $('body');
	const $bodyNotHome = $('body:not(.home)');

	$(document).on('load scroll resize', function() {
		var scrollTop = $(document).scrollTop();

		if ( scrollTop > 0 && !$header.hasClass('is-sticky') ) {
			$header.addClass('is-sticky');
			$body.addClass('has-sticky-menu');
		} else if ( scrollTop <= 0 && $header.hasClass('is-sticky') ) {
			$header.removeClass('is-sticky');
			$body.removeClass('has-sticky-menu');
		}
	});

})( jQuery );
