// Toggle accordion states

(function( $ ) {

	const ANIMATION_SPEED = 300;
	const BUTTON_HTML = '<button class="accordion-button" type="button"><svg aria-hidden="true" focusable="false"><use href="#plus"/></svg></button>';
	let $triggers = $('.js-accordion-trigger');

	if ( !$triggers.length ) {
		return;
	}


	/**
	 * Set up a11y attributes and add interactive elements, e.g. button
	 */
	function init() {
		$triggers.each((index, trigger) => {
			$(trigger).append(BUTTON_HTML);
			$(trigger).next().hide();
		});
	}

	function open( $trigger, $content ) {
		let $button = $trigger.find('button');
		var $parent = $trigger.closest('.accordion-item');

		console.log($trigger);
		console.log($parent);

		$content.slideDown(ANIMATION_SPEED);
		$button.attr('aria-expanded', 'true');
		$content.attr('aria-hidden', 'false');

		$button.find('use').attr('href', '#minus');
		$trigger.addClass('is-open');
		$parent.addClass('is-open');

		$content.on('keyup.accordion', function(event) {
			if ( 'Escape' === event.key ) {
				$button.focus();
				close($trigger, $content);
			}
		});
	}

	function close( $trigger, $content ) {
		let $button = $trigger.find('button');
		var $parent = $trigger.closest('.accordion-item');

		$content.slideUp(ANIMATION_SPEED);
		$button.attr('aria-expanded', 'false');
		$content.attr('aria-hidden', 'true');

		$button.find('use').attr('href', '#plus');
		$trigger.removeClass('is-open');
		$parent.removeClass('is-open');

		$content.off('keyup.accordion');
	}

	function isOpen( $trigger ) {
		let $button = $trigger.find('button');
		return $button.is('[aria-expanded="true"]');
	}

	function toggle( $trigger, $content ) {
		if ( isOpen($trigger) ) {
			close($trigger, $content);
		} else {
			open($trigger, $content);
		}
	}


	init();

	$triggers.on('click', function(event) {
		let $target = $(event.target);
		if ( !$target.is('.js-accordion-trigger') ) {
			$target = $target.closest('.js-accordion-trigger');
		}

		toggle($target, $target.next());
	});
	
})( jQuery );
