// Sharing scripts

( function() {
	function _calc_window_center( width, height ) {
		// Get the top and left offsets required to position the window in center of screen
		return {
			top: (window.screen.height / 2) - (height / 2),
			left: (window.screen.width / 2) - (width / 2)
		};
	}

	function _open_window( width, height, url ) {
		// Calculate position
		var position = _calc_window_center( width, height ),
				windowFeatures = "status=no,height=" + height + ",width=" + width + ",resizable=yes,left=" + position.left + ",top=" + position.top + ",screenX=" + position.left + ",screenY=" + position.top + ",toolbar=no,menubar=no,scrollbars=no,location=no,directories=no";

		window.open( url, 'sharer', windowFeatures );
	}

	function share_to_facebook( width, height, url ) {
		url = url || location.href;
		
		const u = 'http://www.facebook.com/sharer.php?u=' + encodeURIComponent(url);

		_open_window( width, height, u );
		return false;
	}

	function share_to_twitter( width, height, url, text ) {
    url = url || location.href;
    text = text || document.title;

    u = 'https://twitter.com/share?url=' + encodeURIComponent(url) + '&text=' + encodeURIComponent(text);

    _open_window( width, height, u );
      return false;
  }

	// Expose
	window.share_to_facebook = share_to_facebook;
	window.share_to_twitter = share_to_twitter;

} )();
