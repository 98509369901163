(function( $ ) {
	
	const $hamburger = $('.hamburger');
	const $nav = $('.site-nav');
	const $logo = $('.logo-mobile');
	const disableBodyScroll = bodyScrollLock.disableBodyScroll;
	const enableBodyScroll = bodyScrollLock.enableBodyScroll;
	const targetElement = document.querySelector('#js-site-nav');

	$hamburger.on('click', () => {
		if ( $nav.hasClass('is-open') ) {
			CloseMenu();
		} else {
			OpenMenu();
		}
	});

	function OpenMenu() {
		$hamburger.addClass('is-active');
		$logo.addClass('menu-open');
		$nav.addClass('is-open');
		disableBodyScroll(targetElement);
	}

	function CloseMenu() {
		$hamburger.removeClass('is-active');
		$logo.removeClass('menu-open');
		$nav.removeClass('is-open');
		enableBodyScroll(targetElement);
	}

})( jQuery );
